import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import Faq from "../Components/FAQ/FAQ";
import emailjs from "emailjs-com";
import { FaEnvelope, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await emailjs.sendForm(
        "service_wustjwa",
        "template_ci1xlu2",
        e.target,
        "vOQUMCntPsj1Z74hQ"
      );

      setMessage("E-mail enviado com sucesso!");

      setFormData({
        name: "",
        email: "",
        company: "",
        message: "",
      });

      setIsLoading(false);

      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Erro ao enviar o e-mail:", error);
      setMessage("Erro ao enviar o e-mail. Tente novamente mais tarde.");
      setIsLoading(false);
    }
  };

  return (
    <div className="md:w-1/2 mx-auto text-justify my-10 md:my-0">
      <h2 className="text-2xl md:text-4xl font-bold mb-4">Entre em Contato</h2>
      <p className="text-gray-700 mb-6">
        Entre em contato conosco preenchendo o formulário abaixo. Estamos à
        disposição para responder às suas perguntas e fornecer assistência.
      </p>

      {message && (
        <div
          className={`mb-4 ${
            message.includes("sucesso") ? "text-green-600" : "text-red-600"
          }`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 font-semibold mb-2"
          >
            Nome
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Nome Sobrenome"
            className="w-full rounded-lg p-2 border border-gray-300"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 font-semibold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Seu Email"
            className="w-full rounded-lg p-2 border border-gray-300"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="company"
            className="block text-gray-700 font-semibold mb-2"
          >
            Nome da Empresa
          </label>
          <input
            type="text"
            id="company"
            name="company"
            placeholder="Nome da sua empresa"
            className="w-full rounded-lg p-2 border border-gray-300"
            value={formData.company}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-gray-700 font-semibold mb-2"
          >
            Mensagem
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Sua Mensagem"
            rows="4"
            className="w-full rounded-lg p-2 border border-gray-300"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <button
            type="submit"
            className={`w-fit hover:bg-light-blue bg-dark-blue font-semibold text-white px-10 py-3 rounded-full mt-10 transform hover:scale-105 transition-transform duration-300 ease-in-out mx-auto ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <ClipLoader color={"#ffffff"} loading={isLoading} size={20} />{" "}
                Enviando...
              </>
            ) : (
              "Enviar Mensagem"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

function Contact() {
  return (
    <div className="bg-gray-100 overflow-x-hidden">
      <div className="mx-auto w-10/12 py-10 max-w-256 ">
        <div className="flex flex-col md:flex-row border bg-white p-10 rounded-md mb-10">
          <div className="mx-auto">
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
              Informações de Contato
            </h2>
            <p className="text-gray-700 mb-4">
              Entre em contato conosco através dos seguintes meios:
            </p>

            <ul className="mb-6 text-sm">
              <li className="flex items-center mb-2">
                <span className="h-6">
                  <i className="fas fa-phone-alt"></i>
                </span>
                <span>+55 (31) 9 9755-6600</span>
              </li>
              <li className="flex items-center mb-2">
                <span className="h-6">
                  <i className="fas fa-envelope"></i>
                </span>
                <span>contato@globalreference.com.br</span>
              </li>
            </ul>

            <h3 className="text-lg font-semibold mb-2">Redes Sociais</h3>
            <div className="flex space-x-4 mt-2 text-xl">
              <a
                href="https://www.linkedin.com/company/global-reference/"
                className=" hover:text-blue-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
              <a
                href="https://wa.me/+5531997556600"
                className=" hover:text-green-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
              <a
                href="mailto:contato@globalreference.com.br"
                className=" hover:text-light-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaEnvelope />
              </a>
            </div>

            <div className="flex flex-col mt-4 md:mt-0">
              <h2 className="text-lg font-semibold mt-8">Endereço</h2>
              <div className="mt-2">
                <p className="text-sm">Av. Raja Gabáglia, nº 1.617</p>
                <p className="text-sm">5º Andar</p>
                <p className="text-sm">Luxemburgo</p>
                <p className="text-sm">30380-435</p>
                <p className="text-sm">Belo Horizonte – MG</p>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
        <Faq />
      </div>
    </div>
  );
}

export default Contact;
