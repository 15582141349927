import React, { useState } from "react";
import "../../index.css";

function Card({ image, title, content }) {
  const [hovered, setHovered] = useState(
    window.innerWidth >= 1200 ? false : true
  );

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      className={`mx-auto w-64 h-112 md:h-96 bg-black relative rounded-xl  ${
        hovered ? "w-80 h-112" : ""
      } transition-all duration-500 ease-in-out`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className="w-full h-full absolute top-0 left-0 rounded-xl"
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: hovered ? 0.3 : 1,
        }}
      ></div>
      <div className="absolute inset-0 flex items-center justify-center bg-dark-blue bg-opacity-50 rounded-xl">
        <p
          className={`text-white px-5 transition-opacity ease-in text-base text-center ${
            hovered ? "opacity-100 delay-300 duration-500" : "opacity-0"
          }`}
        >
          {content}
        </p>
      </div>
      <h3 className="absolute top-5 left-5 text-white text-xl">{title}</h3>
    </div>
  );
}

export default Card;
