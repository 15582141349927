import { useState } from "react";
import { Collapse } from "react-collapse";
import {
  FaLightbulb,
  FaDatabase,
  FaPeopleCarry,
  FaEnvira,
  FaUsersCog,
} from "react-icons/fa";

const baseColor = "#2CA4DB";

function calculateColor(index) {
  const r = parseInt(baseColor.slice(1, 3), 16);
  const g = parseInt(baseColor.slice(3, 5), 16);
  const b = parseInt(baseColor.slice(5, 7), 16);
  const factor = 0.07 * index;
  const newR = Math.min(255, r + r * factor);
  const newG = Math.min(255, g + g * factor);
  const newB = Math.min(255, b + b * factor);
  return `rgb(${newR}, ${newG}, ${newB})`;
}

const data = [
  {
    title: "Centrados em ESG",
    text: "Agimos de forma ética, transparente e respeitosa. Sustentabilidade e respeito à sociedade são coisa séria para nós. Não apenas palavras em um quadro na parede.",
    icon: FaEnvira,
  },
  {
    title: "Executar com Excelência",
    text: "Usamos do máximo de conhecimento e habilidade que temos para executar cada tarefa com o máximo padrão de qualidade.",
    icon: FaUsersCog,
  },
  {
    title: "Inspirados em dados",
    text: "Em um mundo que coleta milhões de dados por segundo, é nossa inspiração que esses pacotes sejam utilizados para gerar benefício.",
    icon: FaDatabase,
  },
  {
    title: "Inovação",
    text: "Acreditamos que a inovação é a chave para elevar os processos em que atuamos em nossos clientes a um outro patamar.",
    icon: FaLightbulb,
  },
  {
    title: "Parceria e Colaboração",
    text: "Acreditamos no poder da colaboração. Trabalhamos em estreita parceria com nossos clientes e colegas para alcançar objetivos comuns e enfrentar desafios juntos.",
    icon: FaPeopleCarry,
  },
];

function VerticalBars({ title, text, index, openBar, setOpenBar }) {
  const isOpened = openBar === index;

  const toggleBar = () => {
    if (isOpened) {
      return;
    } else {
      setOpenBar(index);
    }
  };

  const { icon: Icon } = data[index];

  return (
    <div
      className={`p-2 cursor-pointer rounded-lg ${
        isOpened ? "w-full pr-5" : "w-1/12 hover:border-4 min-w-8 "
      }`}
      style={{ backgroundColor: calculateColor(index) }}
      onClick={toggleBar}
    >
      <div className="flex items-center flex-col">
        <div className="w-12 h-12 flex items-center justify-center text-white md:text-2xl">
          <Icon />
        </div>
        <Collapse isOpened={isOpened}>
          <div className="ml-4 text-white text-center flex flex-col items-center justify-center ">
            <h3 className="text-xl font-bold mb-5 text-dark-blue">{title}</h3>
            <p className="md:px-5 text-base lg:text-lg pb-5">{text}</p>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

function Values() {
  const [openBar, setOpenBar] = useState(0);

  return (
    <>
      <h2 className="text-2xl text-white md:text-4xl mb-4 mx-auto w-10/12 max-w-256">
        Valores
      </h2>
      <div className="flex min-h-96 md:min-h-64 mx-auto justify-center w-10/12 space-x-1 max-w-256">
        {data.map((item, index) => (
          <VerticalBars
            key={index}
            title={item.title}
            text={item.text}
            index={index}
            openBar={openBar}
            setOpenBar={setOpenBar}
          />
        ))}
      </div>
    </>
  );
}

export default Values;
