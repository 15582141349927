import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  FaEnvelope,
  FaLinkedinIn,
  FaWhatsapp,
  FaArrowUp,
} from "react-icons/fa";
import logo from "../../Images/Logo1.webp";
import emailjs from "emailjs-com";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const [showText, setShowText] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await emailjs.sendForm(
        "service_wustjwa",
        "template_a2k8sg9",
        e.target,
        "vOQUMCntPsj1Z74hQ"
      );

      setMessage("E-mail enviado com sucesso!");

      setFormData({
        email: "",
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao enviar o e-mail:", error);
      setMessage("Erro ao enviar o e-mail. Tente novamente mais tarde.");
      setIsLoading(false);
    }
  };

  return (
    <footer className="bg-dark-blue py-10 text-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-around items-center md:items-start space-y-4 md:space-y-0 md:space-x-8 w-10/12 max-w-256">
        <div className="text-center">
          <img className="w-52 mb-5 mx-auto" src={logo} alt="Logo" />
          <p className="text-sm max-w-40 mx-auto">
            Sua parceira para uma Mineração inteligente
          </p>
        </div>

        <div className="flex flex-col mt-4 md:mt-0">
          <h2 className="text-2xl font-semibold">Endereço</h2>
          <div className="mt-2">
            <p className="text-sm">Av. Raja Gabáglia, nº 1.617</p>
            <p className="text-sm">5º Andar</p>
            <p className="text-sm">Luxemburgo</p>
            <p className="text-sm">30380-435</p>
            <p className="text-sm">Belo Horizonte – MG</p>
          </div>
        </div>

        <div className="mx-auto">
          <h2 className="text-2xl font-semibold">Nossas Redes</h2>
          <div className="flex space-x-4 mt-2 text-xl">
            <a
              href="https://www.linkedin.com/company/global-reference/"
              className="text-gray-300 hover:text-light-blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a
              href="https://wa.me/+5531997556600"
              className="text-gray-300 hover:text-light-blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp />
            </a>
            <a
              href="mailto:contato@globalreference.com.br"
              className="text-gray-300 hover:text-light-blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope />
            </a>
          </div>
          <div className="mt-3">
            <p className="text-sm">(31) 3223-6600</p>
            <p className="text-sm">(31) 99755-6600</p>
          </div>
        </div>

        <div className="mb-4 md:mb-0 space-y-2">
          <p className="text-white">Fique por dentro:</p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row md:space-x-2 items-center"
          >
            <input
              type="email"
              name="email"
              placeholder="Seu e-mail"
              className="bg-white text-dark-blue py-2 px-3 rounded-full focus:outline-none focus:ring focus:border-light-blue"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <button
              type="submit"
              className={`bg-light-blue text-white hover:bg-white hover:text-light-blue py-2 px-4 rounded-full hover:bg-light-blue-dark transition-colors duration-300 mt-3 md:mt-0 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <ClipLoader color={"#ffffff"} loading={isLoading} size={20} />{" "}
                  Enviando...
                </>
              ) : (
                "Inscrever-se"
              )}
            </button>
          </form>
        </div>
      </div>

      <button
        onClick={scrollToTop}
        className={`flex z-50 absolute right-4 bg-light-blue text-white py-2 px-4 rounded-full hover:bg-light-blue-dark transition-colors duration-300 gap-x-5 ${
          showText ? "md:w-auto" : "w-10"
        }`}
        onMouseEnter={() => setShowText(true)}
        onMouseLeave={() => setShowText(false)}
      >
        {showText && (
          <span className="md:inline transition-all duration-500 opacity-100 text-sm">
            Voltar ao topo
          </span>
        )}
        <FaArrowUp className="text-xl" />
      </button>

      <p className="text-center mt-4 text-xs">
        &copy; {new Date().getFullYear()} - Todos os direitos reservados
      </p>
    </footer>
  );
}

export default Footer;
