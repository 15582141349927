import React, { useState } from "react";
import {
  FaTag,
  FaDatabase,
  FaCode,
  FaIndustry,
  FaCommentDollar,
  FaSnowplow,
  FaClipboardList,
  FaUsersCog,
  FaSatelliteDish,
} from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import orepath1 from "../Images/orepath1.webp";
import t2i from "../Images/t2i-logo.webp";
import mark from "../Images/mark.webp";
import treat from "../Images/treat.webp";
import read from "../Images/read.webp";
import register from "../Images/register.webp";
import Mockups from "../Components/Mockups/Mockups";

function AnimatedComponent({ children, animationClass }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`animated ${animationClass} ${inView ? "in-view" : ""}`}
    >
      {children}
    </div>
  );
}

function AnimatedFromTop({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-top">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromBottom({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-bottom">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromLeft({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-left">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromRight({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-right">
      {children}
    </AnimatedComponent>
  );
}

function Problem() {
  return (
    <div>
      <div className="text-center my-10 ">
        <div className="w-10/12 flex mx-auto items-center justify-between max-w-256">
          <div>
            <AnimatedFromTop>
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-start">
                Desafio
              </h2>
              <div className=" text-gray-700 text-justify space-y-5">
                <p>
                  A lavra de várias frentes ao mesmo tempo e a utilização de
                  pilhas de estoque torna praticamente impossível predizer, com
                  alguma exatidão, o blend de minério em que se está
                  trabalhando.
                </p>
                <p>
                  A falta de uma rastreabilidade de materiais deixa sem resposta
                  algumas perguntas como:
                </p>
              </div>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 my-5 text-justify text-sm md:text-base md:text-center">
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Qual ou quais blocos estão sendo alimentados na planta nesse
                  momento?
                </li>
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Qual é a composição do blend do minério nesse momento?
                </li>
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Qual a porcentagem de alimentação direta (direct feed) e
                  retomada de estoque?
                </li>
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Qual é o lead time entre mina e usina ou entre estoque e
                  usina?
                </li>
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Algum caminhão de minério foi destinado à Pilha de Estéril? Ou
                  alguma viagem de estéril foi erroneamente basculada no
                  britador?
                </li>
                <li className="bg-gray-200 rounded-md px-5 py-1 md:py-4">
                  Como realizar a reconciliação geológica utilizando-se como
                  base a informação de teores da usina se não sabemos a origem
                  do material que a alimentou no período?
                </li>
              </ul>
            </AnimatedFromTop>
          </div>
        </div>
        <div className="bg-dark-blue w-full py-10 mt-10 text-white">
          <div className="mt-5 w-10/12 mx-auto max-w-256">
            <div className="mx-auto flex flex-col items-center gap-10 md:flex-row md:justify-evenly">
              <AnimatedFromLeft>
                <div>
                  <img
                    src={t2i}
                    alt="logo da t2i"
                    className="w-40 md:w-56 bg-gray-700 rounded-full border-4 border-white shadow-md drop-shadow-md shadow-slate-600"
                  />
                </div>
              </AnimatedFromLeft>
              <AnimatedFromRight>
                <div className="flex flex-col items-center gap-2 md:w-56 border-2 border-gray-700 py-5 rounded-lg bg-gray-50 text-gray-700">
                  <p className="w-10/12 text-sm">Em pesquisa do t2i</p>
                  <h2 className="text-6xl border-2 font-semibold border-gray-600 rounded-md px-2 pt-2 bg-dark-blue text-white">
                    42%
                  </h2>
                  <p className="w-7/12 text-sm">
                    Dos entrevistados apontam que a rastreabilidade é um desafio
                    que requer inovação
                  </p>
                </div>
              </AnimatedFromRight>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto"></div>
    </div>
  );
}

const solutionData = [
  {
    id: 1,
    image: register,
    title: "Registrar as Tags",
    description:
      "As Tags RFID são registradas para que possamos associá-las a uma área específica de nossa lavra.",
  },
  {
    id: 2,
    image: mark,
    title: "Marcar o Minério",
    description:
      "Tags RFID marcadas são inseridas no material (minério ou estéril) na fase de perfuração e desmoente ou após a detonação.",
  },
  {
    id: 3,
    image: treat,
    title: "Leitura dos Dados",
    description:
      "Antenas e módulos são instalados em pontos estratégicos, como shafts, correias, entradas/saídas de pilhas, alimentação de britadores ou de moinhos. A leitura desses equipamentos possibilita identificar quais materiais estão em trânsito na cadeia de produção mineral.",
  },
  {
    id: 4,
    image: read,
    title: "Tratamento e Ação",
    description:
      "A Global Reference é a única empresa nacional que possui um software específico para rastreabilidade de materiais na indústria da mineração. Através da análise cruzada de informações provenientes dos modelos de blocos, o Global OrePath é capaz de determinar a quantidade e características dos materiais em cada ponto do processo de produção. Se disponíveis, dados do despacho e da usina também podem ser carregados e analisados.",
  },
];

function SolutionDescription({ image, title, description }) {
  return (
    <div>
      <div className="mx-auto text-white flex md:w-1/2 flex-col items-center md:flex-row mt-5">
        <div className="w-3/4 mr-5">
          <img
            src={image}
            alt="imagem da solução"
            className="w-full max-w-160 object-cover border-4 border-b-light-blue rounded-xl"
          />
        </div>
        <div className="md:w-1/2 w-10/12 mt-5 md:mt-0 md:text-start text-justify">
          <h2 className="text-3xl font-bold mb-4">{title}</h2>
          <p className="text-gray-200 text-base ">{description}</p>
        </div>
      </div>
    </div>
  );
}

function Benefits() {
  const icons = [
    FaSnowplow,
    FaIndustry,
    FaClipboardList,
    FaUsersCog,
    FaCommentDollar,
  ];

  const titles = [
    "Mina",
    "Usina",
    "Geologia e Planejamento",
    "Usabilidade",
    "Custo e Implementação",
  ];

  const descriptions = [
    "Análise de dados avançada para melhorar operações de mineração",
    "Otimização de processos na usina para maior eficiência",
    "Soluções de planejamento geológico e análise de recursos",
    "Experiência de usuário intuitiva em qualquer dispositivo",
    "Solução acessível e experiente para implementação eficaz",
  ];

  const texts = [
    [
      "Fácil rastreabilidade do minério",
      "Identificação precisa de perda, carga morta, desvios e lead time",
      "Conhecimento dos efeitos da blendagem",
    ],
    [
      "Predição de qualidade de ROM (Run of Mine)",
      "Uso racional de reagentes",
      "Melhora na recuperação",
    ],
    [
      "Melhora na reconciliação",
      "Redução de MCAF e PCAF",
      "Estimativa de recursos e reservas mais assertiva",
    ],
    [
      "Sistema intuitivo e fácil de operar",
      "Interface amigável",
      "Disponível em dispositivos móveis",
    ],
    [
      "Fração do custo de outras soluções",
      "Equipe treinada e dedicada",
      "Estrutura lean e ágil",
      "Mais de 20 anos de experiência em mineração",
    ],
  ];

  const [selectedIcon, setSelectedIcon] = useState(0);

  const handleIconClick = (index) => {
    setSelectedIcon(index);
  };

  return (
    <section>
      <div className="py-8 sm:py-12 lg:py-16 mx-auto w-10/12 max-w-256">
        <AnimatedFromLeft>
          <div className="mb-6">
            <h2 className="text-3xl font-bold sm:text-4xl">
              Benefícios do Global OrePath
            </h2>
          </div>
        </AnimatedFromLeft>
        <div className="mx-auto md:w-10/12 w-full max-w-256 h-112 md:h-96">
          <AnimatedFromRight>
            <div className="md:w-128 text-center block rounded-xl border border-gray-200  p-4 shadow-sm hover:border-gray-300 hover:ring-2 hover:ring-gray-200 focus:outline-none focus:ring mx-auto">
              <div className="flex items-center justify-center space-x-5 mb-4 mx-auto">
                {icons.map((Icon, index) => (
                  <Icon
                    key={index}
                    className={`text-3xl ${
                      selectedIcon === index
                        ? "text-light-blue cursor-pointer"
                        : "text-gray-400 cursor-pointer"
                    }`}
                    onClick={() => handleIconClick(index)}
                  />
                ))}
              </div>
              <h2 className="font-bold text-xl mt-2">{titles[selectedIcon]}</h2>
              <p className="mt-2 text-gray-400 italic text-sm border border-gray-200 p-2 bg-gray-100 max-w-80 mx-auto rounded-lg">
                {descriptions[selectedIcon]}
              </p>
              <ul className="mt-2 text-base text-gray-600 space-y-4">
                {texts[selectedIcon].map((text, index) => (
                  <li
                    key={index}
                    className="bg-gray-200 w-3/4 mx-auto rounded-lg p-1"
                  >
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </AnimatedFromRight>
        </div>
      </div>
    </section>
  );
}

function Solutions() {
  const [description, setDescription] = useState(0);
  const [selected, setSelected] = useState(0);

  const handleButtonClick = (index) => {
    setSelected(index);
    setDescription(index);
  };

  const getButtonClass = (index) => {
    return selected === index
      ? "py-3 w-1/3 text-light-blue hover:bg-light-blue hover:text-white border-b-4 border-light-blue"
      : "py-3 w-1/3 hover:bg-light-blue border-b-2 border-white hover:text-white";
  };

  return (
    <div className="bg-gray-100">
      <div className="h-fit">
        <div
          className="relative flex justify-center items-center py-20 md:py-40"
          style={{
            backgroundImage: `url(${orepath1})`,
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            backgroundAttachment: "fixed",
            backgroundSize: window.innerWidth < 767 ? "contain" : "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          ></div>

          <AnimatedFromBottom>
            <div className="z-20 text-center flex flex-col items-center gap-5 mx-5 py-14">
              <div className="w-fit hover:bg-light-blue hover:text-white font-semibold bg-white text-light-blue px-10 py-3 rounded-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out border-2 border-white text-2xl">
                <p className="text-2xl">Global OrePath</p>
                <p className="text-sm font-light text-dark-blue">
                  Rastreabilidade de Materiais{" "}
                </p>
              </div>
            </div>
          </AnimatedFromBottom>
        </div>
      </div>

      <Problem />
      <div className=" mx-auto w-10/12 max-w-256">
        <AnimatedFromTop>
          <div className="mb-10">
            <h2 className="mt-14 text-3xl md:text-4xl font-bold mb-4">
              Solução
            </h2>
            <p className="text-gray-700 text-justify">
              O Global OrePath é uma solução completa que utiliza RFID (radio
              frequency identification) para “marcar” e registrar os movimentos
              de materiais na cadeia de produção mineral.
            </p>
          </div>
        </AnimatedFromTop>
      </div>
      <div className="bg-dark-blue py-10">
        <AnimatedFromTop>
          <div className="text-white w-4/5 md:w-1/2 mx-auto flex justify-around">
            <button
              className={getButtonClass(0)}
              onClick={() => handleButtonClick(0)}
            >
              <FaSatelliteDish className="mx-auto" />
            </button>
            <button
              className={getButtonClass(1)}
              onClick={() => handleButtonClick(1)}
            >
              <FaTag className="mx-auto" />
            </button>
            <button
              className={getButtonClass(2)}
              onClick={() => handleButtonClick(2)}
            >
              <FaDatabase className="mx-auto" />
            </button>
            <button
              className={getButtonClass(3)}
              onClick={() => handleButtonClick(3)}
            >
              <FaCode className="mx-auto" />
            </button>
          </div>
        </AnimatedFromTop>
        <AnimatedFromRight>
          {description !== null && (
            <SolutionDescription
              image={solutionData[description].image}
              title={solutionData[description].title}
              icon={solutionData[description].icon}
              description={solutionData[description].description}
            />
          )}
        </AnimatedFromRight>
      </div>
      <Mockups />
      <Benefits />
    </div>
  );
}

export default Solutions;
