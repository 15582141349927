import React, { useState } from "react";
import "../../index.css";

function Collaborator({ employee }) {
  const [hovered, setHovered] = useState(
    window.innerWidth >= 768 ? false : true
  );

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`w-10/12 min-w-72 mx-auto border-2 rounded-xl drop-shadow-lg p-4 flex flex-col items-center h-160 transition-all duration-500 md:max-h-144 bg-white mb-5`}
    >
      <img
        src={employee.image}
        alt="Foto do colaborador"
        className="w-32 h-32 rounded-full border-2 border-dark-blue shadow-lg mb-4"
      />
      <h3 className="text-lg font-semibold mb-2">{employee.name}</h3>
      <div className="flex flex-col gap-y-3 text-gray-700 text-center">
        {employee.description.map((desc, i) => (
          <p key={i} className="text-sm">
            {desc}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Collaborator;
