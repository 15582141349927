import mockup1 from "../../Images/mockup-report.webp";
import mockup2 from "../../Images/mockup-mineflow.webp";
import mockup3 from "../../Images/mockup-home.webp";

import { useInView } from "react-intersection-observer";

function AnimatedComponent({ children, animationClass }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`animated ${animationClass} ${inView ? "in-view" : ""}`}
    >
      {children}
    </div>
  );
}

function AnimatedFromBottom({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-bottom">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromLeft({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-left">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromRight({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-right">
      {children}
    </AnimatedComponent>
  );
}

function Mockups() {
  return (
    <div className="flex flex-col mx-auto">
      <AnimatedFromBottom>
        <div className="text-4xl w-10/12 mx-auto max-w-256 mt-10">
          Conheça o {""}
          <br />
          <h2>
            <span className="bg-light-blue p-2 rounded-md text-white hover:bg-dark-blue  hover:cursor-default hover:bg-gradient-to-tl duration-1000 from-light-blue to-dark-blue transition-bg">
              Global OrePath
            </span>
          </h2>
        </div>
      </AnimatedFromBottom>

      <div className="mx-auto h-fit my-10 flex md:flex-row flex-col items-center gap-y-5 md:gap-y-0 justify-around w-10/12 max-w-256 ">
        <div>
          <AnimatedFromLeft>
            <img
              src={mockup1}
              alt="mockup de uma página do software"
              className="w-96 drop-shadow-2xl mx-auto md:mx-0"
            />
          </AnimatedFromLeft>
        </div>
        <div>
          <AnimatedFromRight>
            <div className="md:w-96">
              <h2 className="text-3xl mb-6 md:mb-9 mt-10">
                Variedade com direcionamento
              </h2>
              <div className="flex flex-col gap-5">
                <p className="text-wrap text-justify text-base">
                  Tenha análises diversas com base em dados coletados em tempo
                  real. Sincronize e relacione dados de diferentes fontes e
                  obtenha insights valiosos para otimizar sua operação. Com
                  nossos anos de experiência, sabemos exatamente o que você
                  quer.
                </p>
              </div>
            </div>
          </AnimatedFromRight>
        </div>
      </div>

      <div className="mx-auto h-fit my-10 flex md:flex-row flex-col items-center md:px-10 gap-y-5 md:gap-y-0 justify-around w-10/12 max-w-256">
        <div className="md:w-1/2 order-2 lg:order-1">
          <AnimatedFromLeft>
            <div className="md:w-96">
              <h2 className="text-3xl mb-6 md:mb-9">Personalização</h2>
              <div className="flex flex-col gap-5">
                <p className="text-wrap text-justify text-base">
                  Possibilitamos a personalização do fluxo de trabalho de acordo
                  com as necessidades de sua mina. Nossas soluções são
                  projetadas para atender às suas necessidades específicas,
                  garantindo que você tenha o que precisa, quando precisa.
                </p>
              </div>
            </div>
          </AnimatedFromLeft>
        </div>
        <div className=" order-1 mx-auto lg:order-2 flex md:flex-row flex-col items-center md:px-10 gap-y-5 md:gap-y-0 justify-around w-10/12 max-w-256 ">
          <AnimatedFromRight>
            <img
              src={mockup2}
              alt="mockup de uma página do software"
              className="w-96 drop-shadow-2xl mx-auto md:mx-0"
            />
          </AnimatedFromRight>
        </div>
      </div>

      <div className="mx-auto h-fit my-10 flex md:flex-row flex-col items-center md:px-10 gap-y-5 md:gap-y-0 justify-around w-10/12 max-w-256 ">
        <div>
          <AnimatedFromLeft>
            <img
              src={mockup3}
              alt="mockup de uma página do software"
              className="w-96 drop-shadow-2xl mx-auto md:mx-0"
            />
          </AnimatedFromLeft>
        </div>
        <div>
          <AnimatedFromRight>
            <div className="md:w-96">
              <h2 className="text-3xl mb-6 md:mb-9">Tudo em um Só Lugar</h2>
              <div className="flex flex-col gap-5">
                <p className="text-wrap text-justify text-base">
                  Acompanhe o fluxo de minério em tempo real ou visualize dados
                  de um período específico. Nossa solução permite que você tenha
                  acesso a dados históricos e em tempo real, tudo em um só
                  lugar.
                </p>
              </div>
            </div>
          </AnimatedFromRight>
        </div>
      </div>
    </div>
  );
}

export default Mockups;
