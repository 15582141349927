import React, { useEffect, useState } from "react";
import logo from "../../Images/Logo1.webp";

function Header() {
  const [hidden, setHidden] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (prevScrollY < currentScrollY) {
        setHidden(true);
      } else {
        setHidden(false);
      }

      prevScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleLinkClick = (sectionId) => {
    const currentPath = window.location.pathname;
    if (currentPath !== "/") {
      window.history.pushState(null, null, "/");
    }

    setTimeout(() => {
      scrollTo(sectionId);
    }, 50);
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownItemClick = (sectionId) => {
    handleLinkClick(sectionId);
    setDropdownOpen(false);
  };

  return (
    <header
      className={`z-50 w-full h-32 fixed top-0 text-white p-4 bg-gradient-to-t from-transparent to-dark-blue transition-transform duration-300 ease-in-out transform ${
        hidden ? "-translate-y-full" : "translate-y-0"
      }`}
    >
      <div className="container mx-auto flex flex-col items-center gap-y-3 text-center">
        <img className="w-36" src={logo} alt="Logo" />
        <nav className="flex items-center">
          <ul className="flex md:gap-x-10 gap-x-5">
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="/"
                className="text-white hover:text-light-blue transition-colors duration-300"
                onClick={() => handleLinkClick("home")}
              >
                Home
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className="text-white hover:text-light-blue transition-colors duration-300"
                onClick={() => handleLinkClick("quemsomos")}
              >
                Quem Somos
              </a>
            </li>
            <li>
              <div
                className="relative"
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  className="text-white hover:text-light-blue transition-colors duration-300"
                  onClick={handleDropdownClick}
                >
                  Soluções
                </a>
                {dropdownOpen && (
                  <ul className="absolute top-full left-0 shadow-md py-2 rounded-lg w-36 -ml-10 bg-white bg-opacity-75 transition-transform duration-300 ease-in-out transform">
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        className="text-gray-800 hover:text-light-blue px-4 py-2 block"
                        onClick={() =>
                          handleDropdownItemClick("solucoes-opcao1")
                        }
                      >
                        Global OrePath
                      </a>
                    </li>
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href="#"
                        className="text-gray-800 hover:text-light-blue px-4 py-2 block"
                        onClick={() =>
                          handleDropdownItemClick("solucoes-opcao2")
                        }
                      >
                        Global MineMatch
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className="text-white hover:text-light-blue transition-colors duration-300"
                onClick={() => {
                  handleLinkClick("contato");
                }}
              >
                Contato
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
