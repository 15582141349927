import antonio from "../Images/profile_antonio.webp";
import guilherme from "../Images/profile_guilherme.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Values from "../Components/Values/Values";
import { useInView } from "react-intersection-observer";
import Collaborator from "../Components/Collaborators/Collaborators";

function AnimatedComponent({ children, animationClass }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`animated ${animationClass} ${inView ? "in-view" : ""}`}
    >
      {children}
    </div>
  );
}

function AnimatedFromTop({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-top">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromBottom({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-bottom">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromLeft({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-left">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromRight({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-right">
      {children}
    </AnimatedComponent>
  );
}

const employeesData = [
  {
    name: "Antônio Caires",
    image: antonio,
    description: [
      "Engenheiro de minas formado pela UFMG com 21 anos de experiência em minas a céu aberto e subterrâneas.",
      "Tem um conhecimento em planejamento de mina, excelência operacional, gerenciamento de projetos, desenvolvimento de estudos, em mais de dez diferentes commodities e diversos países.",
      "Antonio tem pós-graduação em desenvolvimento de negócios pela Fundação Dom Cabral, é Green Belt em Six Sigma e especialista em Teoria das Restrições (TOC). Também é mestrando em planejamento de lavra na UFRGS",
      "É Fellow e CP (min) da AusIMM e credenciado na CBRR (Comissão Brasileira de Recursos e Reservas Minerais).",
    ],
  },
  {
    name: "Guilherme Junqueira",
    image: guilherme,
    description: [
      "Desenvolvedor e estudante de Engenharia de Software pela FIAP, com sólida formação em mineração pela UFMG.",
      "Possui experiência em desenvolvimento e gestão de projetos, aplicando tecnologia para aumentar a eficiência na indústria da mineração por meio da análise de dados.",
      "Como funcionário da Deswik, Guilherme desempenhou um papel fundamental nos processos internos, facilitando a escalabilidade de uma empresa de software.",
      "Comprometido com o aprendizado contínuo, busca constantemente se atualizar sobre as últimas tendências e inovações.",
    ],
  },
];

function AboutUs() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <div className=" w-10/12 mx-auto py-20 space-y-5 max-w-256">
          <AnimatedFromTop>
            <h2 className="text-4xl text-light-blue mb-5">Quem Somos</h2>
            <div className="text-justify border bg-white p-5 rounded-xl drop-shadow-lg">
              <h2 className="text-2xl  md:text-4xl font-bold mb-4">
                Nossa História
              </h2>
              <p className=" md:text-lg ">
                A Global Reference surge em 2024 inspirada em um grupo de
                profissionais visionários que estudam inovações para a indústria
                de mineração. Intitulado t2i – Think to Innovate, esse grupo
                acredita que inovar é a chave para uma indústria mais eficiente
                e segura.
              </p>
            </div>
          </AnimatedFromTop>
          <div className="md:flex md:gap-5">
            <AnimatedFromLeft>
              <div className=" text-justify border bg-white p-5 rounded-xl drop-shadow-lg">
                <h2 className="text-2xl md:text-4xl font-bold mb-4">Missão</h2>
                <p className="text-gray-700">
                  A GR existe para desenvolver e conectar tecnologias e
                  processos de ciência de dados, solucionando desafios diários
                  em geologia, planejamento e operação de minas. Posicionamo-nos
                  para compreender as particularidades de cada cliente, criando
                  soluções que geram valor real.
                </p>
              </div>
            </AnimatedFromLeft>
            <AnimatedFromRight>
              <div className=" text-justify mt-5 md:mt-0 border bg-white p-5 rounded-xl drop-shadow-lg">
                <h2 className="text-2xl md:text-4xl font-bold mb-4">Visão</h2>
                <p className="text-gray-700">
                  A nome de nossa empresa é um spoiler. Almejamos nos tornar
                  Referência Global no trabalho com ciência de dados na
                  indústria da mineração até 2030. Queremos garantir que as
                  soluções presentes em nosso portfólio sejam lembradas como
                  benchmarks por nossos clientes.
                </p>
              </div>
            </AnimatedFromRight>
          </div>
        </div>
      </div>
      <div className="py-10 bg-dark-blue w-full mx-auto">
        <AnimatedFromLeft>
          <Values />
        </AnimatedFromLeft>
      </div>
      <div className=" mx-auto">
        <div className="p-10 mx-auto md:px-0 md:w-10/12 max-w-256">
          <AnimatedFromBottom>
            <h2 className="text-2xl md:text-4xl font-bold mb-4">
              Colaboradores
            </h2>
          </AnimatedFromBottom>
          <AnimatedFromRight>
            <div className="mx-auto">
              <Slider {...settings}>
                {employeesData.map((employee, index) => (
                  <div key={index}>
                    <Collaborator employee={employee} />
                  </div>
                ))}
              </Slider>
            </div>
          </AnimatedFromRight>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
