import React from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4 bg-white rounded-lg shadow-lg">
      <div
        className="flex px-10 py-1 items-center justify-between cursor-pointer"
        onClick={toggleOpen}
      >
        <p className=" font-semibold my-2">{question}</p>
        <div className="transform rotate-0 transition-transform duration-300">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isOpen && (
        <div className="p-2">
          <p className="text-gray-700 px-10">{answer}</p>
        </div>
      )}
    </div>
  );
}

const faqData = [
  {
    question:
      "O que é o software Global OrePath e como ele pode beneficiar minha empresa?",
    answer:
      "O Global OrePath é uma plataforma tecnológica que ajuda a coletar, analisar e interpretar dados relevantes para a indústria da mineração com a ajuda da tecnologia RFID. Ele oferece insights valiosos que ajudam a reduzir os erros das operações de mineração.",
  },
  {
    question:
      "Como o seu software se diferencia de outras soluções no mercado?",
    answer:
      "Além de termos mais de 20 anos de experiência no setor e, com isso, conhecermos exatamente os desafios da indústria, o Global OrePath busca oferecer uma solução completa para a mineração, desde a coleta de dados até a análise e interpretação dos mesmos. Além disso, nosso software é altamente customizável, podendo ser adaptado para as necessidades específicas de cada cliente.",
  },
  {
    question:
      "Como faço para implementar o Global OrePath em minha operação de mineração?",
    answer:
      "A implementação do Global OrePath se inicia com um contato com nosso time para a realização de uma análise detalhada de sua operação. A partir disso, podemos entender melhor suas necessidades e oferecer uma solução customizada para sua empresa. Entre em contato conosco para saber mais!",
  },
  {
    question: "Quais são os custos associados à implementação da solução?",
    answer:
      "Os custos variam dependendo do escopo da implementação e das necessidades específicas do cliente. Porém, não obtemos lucro na instalação de equipamentos, apenas na venda de licenças de uso do software. Entre em contato conosco para saber mais!",
  },
];

function Faq() {
  return (
    <div>
      <h2 className="text-2xl md:text-4xl font-bold mb-4">
        Perguntas Frequentes
      </h2>
      {faqData.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
}

export default Faq;
