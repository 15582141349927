import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.css";
import Card from "../Components/Cards/Cards";
import bgImage1 from "../Images/1.webp";
import bgImage2 from "../Images/2.webp";
import bgImage3 from "../Images/3.webp";
import bgImage4 from "../Images/4.webp";
import bgImage5 from "../Images/5.webp";
import bgImage6 from "../Images/6.webp";
import bgImage7 from "../Images/7.webp";
import bgImage8 from "../Images/8.webp";
import placeholder from "../Images/23.webp";
import mineroutes from "../Images/mineroutes.webp";
import pile from "../Images/charge-pile.webp";
import reconciliate from "../Images/Reconciliation.webp";
import datawork from "../Images/datawork.webp";
import { useInView } from "react-intersection-observer";
import AboutUs from "./About";
import Solutions from "./Solutions";
import "../index.css";
import Contact from "./Contact";

function AnimatedComponent({ children, animationClass }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      ref={ref}
      className={`animated ${animationClass} ${inView ? "in-view" : ""}`}
    >
      {children}
    </div>
  );
}

function AnimatedFromTop({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-top">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromBottom({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-bottom">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromLeft({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-left">
      {children}
    </AnimatedComponent>
  );
}

function AnimatedFromRight({ children }) {
  return (
    <AnimatedComponent animationClass="animated-from-right">
      {children}
    </AnimatedComponent>
  );
}

const cardData = [
  {
    id: 1,
    image: mineroutes,
    title: "Personalização",
    content:
      "Na Global Reference, começamos com a compreensão das especificidades do seu negócio. Utilizamos nossa experiência para identificar as necessidades exclusivas da sua mina, garantindo soluções personalizadas para atender aos seus objetivos. Sua mina é única, e nossa personalização reflete isso.",
  },
  {
    id: 2,
    image: pile,
    title: "Experiência",
    content:
      "À diferença de outros softwares, o Global OrePath foi concebido por especialistas em mineração. Nossa equipe possui experiência prática em operações de mina, o que nos capacita a compreender as complexidades do seu negócio e a oferecer soluções que atendam às suas necessidades específicas.",
  },
  {
    id: 3,
    image: datawork,
    title: "Data Intelligence",
    content:
      "Transformamos dados brutos em insights valiosos. Nossos algoritmos avançados e análises poderosas garantem que você tenha informações acionáveis para otimizar operações, melhorar a manutenção de equipamentos e tomar decisões estratégicas de forma mais eficiente. Com nosso tratamento de dados, você fica à frente da concorrência.",
  },
];

export default function Homepage() {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const quemSomosRef = useRef(null);
  const solucoesRef = useRef(null);
  const contatoRef = useRef(null);

  function scrollToSection(ref) {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="overflow-x-hidden bg-gray-100">
      <div className="bg-dark-blue relative overflow-hidden ">
        <div className="w-full h-screen flex items-center justify-center opacity-45">
          {[
            { image: bgImage1, translateX: -0.01, translateY: -0.01 },
            { image: bgImage2, translateX: -0.03, translateY: 0.03 },
            { image: bgImage3, translateX: -0.04, translateY: 0.02 },
            { image: bgImage4, translateX: -0.05, translateY: -0.02 },
            { image: bgImage5, translateX: -0.06, translateY: 0.02 },
            { image: bgImage6, translateX: -0.07, translateY: 0.07 },
            { image: bgImage7, translateX: -0.05, translateY: 0.1 },
            { image: bgImage8, translateX: -0.09, translateY: -0.09 },
          ].map((bg, index) => (
            <div
              key={index}
              className="absolute inset-0 bg-center bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url(${bg.image})`,
                width: "110%",
                height: "110%",
                transform: `translate(${mouseX * bg.translateX}px, ${
                  mouseY * bg.translateY
                }px)`,
                backgroundPosition: "center",
              }}
            />
          ))}
          <img
            className="z-30 animate-moveX"
            src={bgImage6}
            alt="nevoa em movimento"
          ></img>
          <img
            className="z-30 animate-moveX"
            src={bgImage4}
            alt="nevoa em movimento 2"
          ></img>
        </div>
        <div className="inset-0 flex flex-col items-center justify-center text-white text-center absolute z-10">
          <h1 className="text-5xl w-10/12 uppercase text-wrap md:w-160">
            Ciência de <span className="text-light-blue">Dados</span> à serviço
            da Mineração
          </h1>

          <Link onClick={() => scrollToSection(solucoesRef)}>
            <button className="hover:bg-light-blue bg-dark-blue  text-white px-10 py-3 rounded-full mt-10 transform hover:scale-105 transition-transform duration-300 ease-in-out">
              Saiba Mais
            </button>
          </Link>
        </div>
      </div>

      <div className="h-fit max-w-256 mx-auto md:w-10/12">
        <div className="md:flex md:flex-row md:items-start md:text-justify my-20 flex flex-col text-justify md:mx-auto mx-5 justify-between">
          <AnimatedFromLeft>
            <h2 className="text-4xl mb-9 max-w-64 md:max-w-176 text-start">
              Explore a mineração
              <br />{" "}
              <span className="bg-light-blue p-2 rounded-md text-white hover:bg-dark-blue  hover:cursor-default hover:bg-gradient-to-tl duration-1000 from-light-blue to-dark-blue transition-bg">
                Data-Driven
              </span>
            </h2>
          </AnimatedFromLeft>
          <AnimatedFromRight>
            <div className="flex flex-col gap-5">
              <p className="w-full max-w-96 text-wrap">
                A Global Reference está na vanguarda da Mineração 4.0. Unimos
                Ciência de Dados e inovação para criarmos soluções avançadas que
                elevam a eficiência e reduzem os erros na cadeia de produção
                mineral.
              </p>
              <p className="max-w-96">
                Junte-se a nós para moldar hoje o futuro da mineração.
              </p>
              <Link
                to="#quemsomos"
                className="w-fit hover:bg-light-blue bg-dark-blue font-semibold text-white px-10 py-3 rounded-full mt-5 transform hover:scale-105 transition-transform duration-300 ease-in-out mx-auto"
                onClick={() => scrollToSection(quemSomosRef)}
              >
                Quem Somos
              </Link>
            </div>
          </AnimatedFromRight>
        </div>
      </div>

      <div className="h-128">
        <div
          className="relative flex justify-center items-center"
          style={{
            backgroundImage: `url(${placeholder})`,
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>
          <AnimatedFromBottom>
            <div className="z-20 text-center flex flex-col items-center gap-5 mx-5">
              <h2 className="text-3xl md:text-4x1 text-white">
                Conheça o <br /> Global OrePath
              </h2>
              <p className=" text-white max-w-96 flex-wrap text-center">
                Além de uma solução inovadora, entregamos um Software robusto
                pensado por quem entende de mineração.
              </p>
              <Link
                className="w-fit hover:bg-light-blue hover:text-white font-semibold bg-white text-light-blue px-10 py-3 rounded-full transform hover:scale-105 transition-transform duration-300 ease-in-out"
                onClick={() => scrollToSection(solucoesRef)}
              >
                A Solução
              </Link>
            </div>
          </AnimatedFromBottom>
        </div>
      </div>
      <div id="quemsomos" ref={quemSomosRef}>
        <AboutUs />
      </div>
      <h2
        id="solucoes"
        ref={solucoesRef}
        className="w-10/12 text-4xl mx-auto text-light-blue mb-10 max-w-256"
      >
        Nossas Soluções
      </h2>
      <div id="solucoes-opcao1">
        <Solutions />
      </div>

      <AnimatedFromRight>
        <div className="mx-auto mb-32 max-w-256 w-10/12">
          <Slider {...settings}>
            {cardData.map((card) => (
              <div key={card.id}>
                <Card
                  image={card.image}
                  title={card.title}
                  content={card.content}
                />
              </div>
            ))}
          </Slider>
        </div>
      </AnimatedFromRight>

      <div id="solucoes-opcao2" className="h-fit">
        <div
          className="relative flex justify-center items-center py-20 md:py-28"
          style={{
            backgroundImage: `url(${reconciliate})`,
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            backgroundSize: "contain",
            backgroundAttachment: "fixed",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
          ></div>
          <AnimatedFromBottom>
            <div className="z-20 text-center flex flex-col items-center gap-5 mx-5 py-14">
              <div className="w-fit hover:bg-light-blue hover:text-white font-semibold bg-white text-light-blue px-10 py-3 rounded-2xl transform hover:scale-105 transition-transform duration-300 ease-in-out border-2 border-white text-2xl">
                <p className="text-2xl">Global MineMatch</p>
                <p className="text-sm font-light text-dark-blue">
                  Reconciliação Avançada{" "}
                </p>
              </div>
            </div>
          </AnimatedFromBottom>
        </div>
      </div>
      <div className=" mx-auto w-10/12 max-w-256">
        <AnimatedFromTop>
          <div className="mb-10">
            <h2 className="mt-14 text-3xl md:text-4xl font-bold mb-4">
              Em Desenvolvimento
            </h2>
            <p className="text-gray-700 text-justify">
              Acreditamos que possamos dar o próximo passo no processo de
              reconciliação de mina. Estamos trabalhando nessa solução para que
              você possa ter uma visão mais clara e precisa de sua operação.
              Fique atento para mais novidades.
            </p>
          </div>
        </AnimatedFromTop>
      </div>
      <div id="contato" ref={contatoRef}>
        <Contact />
      </div>
    </div>
  );
}
